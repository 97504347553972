.mob-had {
    display: none;
}

.web-had {
    display: block;
}

@media only screen and (max-width: 768px) {
    .web-had {
        display: none;
    }

    .mob-had {
        display: block !important;
        background-color: #148BA5 !important;
    }
}

/* Color Scheem */
/* Green */
.-green {
    background-color: #12502E;
}

.-greenSub:hover {
    background-color: #7bb595;
    color: white;
}

.act_green {
    background-color: #3A7555;
    color: white;

}

.h-green:hover {
    background-color: #3a75556f;
    color: white !important;
}

.bg-LGreen {
    background-color: #BBDAAF;
}

.bg-LGreen_ {
    background-color: #C8DCC7;
}

/* teal */
.-teal {
    background-color: #016367;
}

.-tealSub:hover {
    background-color: #45d1d1;
    color: white;
}

.act_teal {
    background-color: #01949A;
    color: white;

}

.bg-LTeal {
    background-color: #D5ECEC;
}

.h-teal:hover {
    background-color: #01959a7e;
    color: white !important;
}

/* maroon */
.-maroon {
    background-color: #842528;
}

.-maroonSub:hover {
    background-color: #eba5a8;
    color: white;
}

.act_maroon {
    background-color: #c47b7e;
    color: white;

}

.bg-LMaroon {
    background-color: #ebd6d7;
}


.h-maroon:hover {
    background-color: #c47b7e;
    color: white !important;
}

/* indigo */
.act_indigo {
    background-color: #d382e1;
    color: white;

}

.-indigoSub:hover {
    background-color: #f4a5ff;
    color: white;
}

.-indigo {
    background-color: #9A21AD;
}

.bg-LIndigo {
    background-color: #f1dcf4;
}

.h-indigo:hover {
    background-color: #d382e1;
    color: white !important;
}

/* blue */
.-blue {
    background-color: #215380;
}

.-blueSub:hover {
    background-color: #b4d1ed;
    color: white;
}

.act_blue {
    background-color: #3E88CA;
    color: white;

}

.h-blue:hover {
    background-color: #3e89ca7c;
    color: white !important;
}

.bg-LBlue {
    background-color: #c4d7e9;
}

/* charcole */
.-charcole {
    background-color: #313131;
    /* 3F454C */
}

.-charcoleSub:hover {
    background-color: #c2c4c7;
    color: white;
}

.act_charcole {
    background-color: #44474E;
    color: white;

}

.h-charcole:hover {
    background-color: #44474E;
    color: white !important;
}

.bg-LCharcole {
    background-color: #c2c4c7;
}

/* BG-Sidebar */

/* Lightg Green */
.-lgreen {
    background-color: #27ae61;
}

.act_lgreen {
    background-color: #27ae61;
    color: white;

}

.h-lgreen:hover {
    background-color: #259e59;
    color: white !important;
}

.bg-lgreen {
    background-color: #dcfce7;
}




/* .item-active-green {
    background-color: #3A7555;
    /* background-color: #7C8186; */


.icon_16_CFC {
    background-position: -2px -56px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 15px;
    height: 14px;
    background-repeat: no-repeat;
    display: inline-block;
}

/* .icon_16_FSF {
    background-position: -0px -36px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
} */

.icon_16_FSF {
    background-position: -324px -324px;
    background-image: url('/src/Assets/images/_sprite_ic16_all.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_RF {
    background-position: -306px -324px;
    /* background-image: rl(/_sprite_ic16_all.png); */
    background-image: url('/src/Assets/images/_sprite_ic16_all.png');

    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

/* 
.icon_16_action_medium {
    background-position: -18px -36px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_action_high {
    background-position: -36px -36px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_action_critical {
    background-position: -54px -36px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_delete,
.icon_16_cross {
    background-position: -72px -36px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_rf {
    background-position: -306px -324px;
    background-image: url('/src/Assets/images/_sprite_ic16_all.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}



.icon_16_cfc {
    background-position: -0px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_clinician {
    background-position: -18px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_nursing {
    background-position: -36px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_laboratory,
.icon_16_dept_lab {
    background-position: -54px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_patientservices,
.icon_16_dept_admin {
    background-position: -72px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_clinicaladministration,
.icon_16_dept_clinicaladministrator,
.icon_16_dept_clinicaladmin {
    background-position: -90px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_dept_medicalliaison,
.icon_16_dept_liaison {
    background-position: -108px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_scan_results {
    background-position: -126px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_treatment_plan {
    background-position: -144px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_booking_form {
    background-position: -162px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_prescription {
    background-position: -180px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}


.icon_16_xls,
.icon_16_excel {
    background-position: -198px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_pdf {
    background-position: -216px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_practicaltraining {
    background-position: -234px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_diploma {
    background-position: -252px -54px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_clinic {
    background-position: -0px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_transfer {
    background-position: -18px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_merge {
    background-position: -36px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_gend_1,
.icon_16_male {
    background-position: -54px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_gend_2,
.icon_16_female {
    background-position: -72px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_thumbs_up {
    background-position: -90px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_thumbs_down {
    background-position: -108px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_undo {
    background-position: -126px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_redo {
    background-position: -144px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_halftick {
    background-position: -162px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_partial {
    background-position: -180px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_no {
    background-position: -198px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_yes {
    background-position: -216px -72px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_spinner {
    background-position: center center;
    background-image: url(/src/Assets/images/loading_16.gif);
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_non_conformance {
    background-position: -378px -252px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_safety_incident {
    background-position: -396px -252px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon_16_issue_register {
    background-position: -414px -252px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
}


.icon_16_issue_register {
    background-position: -414px -252px;
    background-image: url('/src/Assets/images/icons_all_16.png');
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
} */
.nav-green {
    background-color: #3A7555;
}

.nav-lgreen {
    background-color: #2dcc70;
}

.nav-teal {
    background-color: #01949A;
}

.nav-charcole {
    background-color: #838A9A;
}

.nav-maroon {
    background-color: #A44548;
}

.nav-indigo {
    background-color: #BD60CC;
}

.nav-blue {
    background-color: #3573AB;
}

.nav-charcole {
    background-color: #44474E;
}

/* ----- */
.dot-CFC {
    display: inline-block;
    border-radius: 7px;
    border-top-left-radius: 2px;
    height: 15px;
    width: 15px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-CFC:after {
    font-family: Arial, Helvetica, sans-serif;
    content: "\20E0";
    font-weight: normal;
    text-shadow: none;
    color: #fff;
    opacity: 0;
    font-size: 13px;

    line-height: 50%;

}


.dot-RF {
    width: 16px;
    height: 16px;
    border-radius: 7px;
    border-top-right-radius: 2px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dot-RF:after {
    font-family: Arial, Helvetica, sans-serif;
    content: "\2665";
    font-weight: normal;
    text-shadow: none;
    color: #fff;
    opacity: 0.9;
    font-size: 13px;
    /* position: relative;
    top: -1px;
    left: 0.5px; */
    line-height: 50%;
}


.dot-FSF {
    width: 23px;
    height: 23px;
    border-radius: 7px;
    border-bottom-left-radius: 2px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dot-FSF:after {
    font-family: Arial, Helvetica, sans-serif;
    content: "\29BF";
    font-weight: normal;
    text-shadow: none;
    color: #fff;
    opacity: 0.9;
    font-size: 10px;
    /* position: relative;
    top: -1.5px;
    left: -2px; */
    line-height: 50%;
}

/*li.ui-menu-item .dot-RF:after,
li.ui-menu-item .dot-rf:after { position:relative; top:-4px!important; left:0px; }*/

.dot-PPN {
    width: 13px;
    height: 13px;
    border-radius: 3px !important;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dot-PPN:after {
    content: "\03C1";
    color: #fff;
    font-size: 13px;

}


.dot-ppn:after {
    top: -2px !important;
}


.dot-PPR {
    width: 13px;
    height: 13px;
    border-radius: 3px !important;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-PPR:after {
    content: "\2665";
    color: #fff;
    font-size: 13px;
    line-height: 10px;

}

.carousel-item {
    cursor: pointer;
    -webkit-transition: 500ms all;
    transition: 500ms all;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    position: absolute;
    /* left: 15%; */
    top: 0;

}

.carousel-item.hover-effect {
    -webkit-transform: scale(1.5);
    top: 20;
    opacity: 1;
    transform: translateY(70px);
}

/* .carousel-item:hover {
    -webkit-transform: scale(1.5);
    top: 20;
    opacity: 1;
    transform: translateY(70px);

} */

/* .carousel-item:hover~.carousel-item {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
} */
.ReactModal__Content.ReactModal__Content--after-open {
padding: 0px!important;
height:550px;
width: 70%;
/* left: 50%; */
/* left: 23%; */
inset: auto!important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{

background-color: rgb(2 2 2 / 75%)!important;
justify-content: center;
align-items: center;
display: flex;
}
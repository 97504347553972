/* Theme Colors */
/* green */
.tableHeadrTPg {
    background-color: #3A7555;
    /* color: red; */
}

.table_UpdateHeader_g {
    background-color: #628f72;
}

.toggle-checkbox_g:checked+.toggle-label {
    background-color: #3A7555;
}

/* Light green */
.tableHeadrTPlg {
    background-color: #27ae61;
    /* color: red; */
}

.table_UpdateHeader_lg {
    background-color: #27ae61;
}

.toggle-checkbox_lg:checked+.toggle-label {
    background-color: #3A7555;
}

/* teal */
.tableHeadrTPt {
    background-color: #22A2A7;
}

.table_UpdateHeader_t {
    background-color: #00a0a7;
}

.toggle-checkbox_t:checked+.toggle-label {
    background-color: #01949A;
}


/* maroon */

.tableHeadrTPm {
    background-color: #8f3f42;
}

.table_UpdateHeader_m {
    background-color: #a76b6d;
}


.toggle-checkbox_m:checked+.toggle-label {
    background-color: #943033;
}



/* indigo */
.tableHeadrTPi {
    /* background-color: #a753b5; */
    background-color: #BD60CC;
}

.table_UpdateHeader_i {
    background-color: #d190db;
}

.toggle-checkbox_i:checked+.toggle-label {
    background-color: #c912cf;
}

/* blue */
.tableHeadrTPb {
    /* background-color: #a753b5; */
    background-color: #6086cc;
}

.table_UpdateHeader_b {
    background-color: #3E88CA;
}

.toggle-checkbox_b:checked+.toggle-label {
    background-color: #3E88CA;
}

/* charcole */
.table_UpdateHeader_c {
    background-color: #44474E;
}

.toggle-checkbox_c:checked+.toggle-label {
    background-color: #313131;
}

.table_UpdateHeader_c {
    background-color: #44474E;
}

/* Handle the toggle state */



.height-80 {
    height: 100%;
}

.drop_shadow {
    /* box-shadow: 0px 1px 5px 1px #aaaaaa; */
    box-shadow: 0px 0px 4px rgba(18, 80, 46, 0.25);
}

.width-98 {
    width: 99%;
}

.css-1hv8oq8-MuiStepLabel-label {
    color: #BCBCBC;
    font-weight: 500;
}

.MuiStepLabel-label.Mui-completed.css-1hv8oq8-MuiStepLabel-label {
    color: #BCBCBC;
}

.Mui-active {
    color: #FFFFFF;
}

.btnMrgn {
    margin: 178px 0px 110px 0px;
}

.pAbslut {
    position: absolute;
    top: 107px;
    /* left: 91px; */
    right: -88px;

}

.w-33 {
    width: 24.7%;
    /* width: 33%; */
    height: 120px;
    overflow: hidden;
    /* border-radius: 10px; */

}

@media only screen and (max-width: 600px) {
    .w-33 {
        width: 100%;
    }
}

@media only screen and (max-width:768px) {
    .w-33 {
        width: 100%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* ======== */
/* Hide the checkbox visually */
.toggle-checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Toggle Switch Styles */
.toggle-label {
    display: inline-block;
    width: 1.85rem;
    height: 1rem;
    background-color: #ccc;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.toggle-label::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s ease;
}

.toggle-checkbox:checked+.toggle-label::after {
    transform: translateX(13px);
}

.hovBtn {
    width: 15px;
    height: 15px;
}

.t16 .tflex {
    display: flex;
    /* border-bottom: 1px solid rgb(104, 39, 255); */
    flex-wrap: wrap;
    padding: 2px 0px;
    /* width: 75%; */

}

.t16 .tflex .tflex1 {
    display: flex;
    /* flex-wrap: wrap; */
    /* background-color: #44474E!important; */
    /* border-top: 1px solid rgba(59, 59, 59, 0.11); */
    /* border-bottom: 1px solid rgba(59, 59, 59, 0.11); */
}

/* ======= */

/* .toggle_g:checked+.slider {
    background-color: #026613;
} */

/* 
input:checked+.slider {
    background-color: #093344;
} */

/* .slider:checked {
    background-color: #093344;
} */



/* input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
} */



button:hover+#tooltip-light {
    visibility: visible;
}

.tb-height {
    height: 88%;
    /* padding: 46px 0px 38px 5px; */
}


.height-36 {
    /* height: 46px;
    position: absolute;
    top: 0;
    z-index: 5; */
}

.height-61 {
    /* height: 36px; */
    /* position: absolute; */
    /* bottom: 0; */
    /* z-index: 55; */
    height:97%;
    padding: 255px 0px 0px 0px;
}

.hit-100 {
    height: 100%;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



.buttong {
    /* background-color: green; */
    accent-color: #5729e9;
    color: white;
}

.buttong.active {
    background-color: red;
    color: white;

}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.select__input {
    height: 17px
}

.select__indicators.css-1hb7zxy-IndicatorsContainer {
    height: 22px;
}

.css-1xc3v61-indicatorContainer {
    padding: 0px !important;
}

.css-wsp0cs-MultiValueGeneric {


    font-size: 8px !important;
    padding: 3px 0px 3px 2px !important;
    padding-left: 0px;
    box-sizing: border-box;
}

.select__multi-value__remove.css-12a83d4-MultiValueRemove {
    padding: 0px 0px 0px 0px!important ;
}

.select__multi-value.css-8qaamg-multiValue {
    margin: 0px 2px 0px 0px;

}

.select__control.css-1kz4mno-control {
    min-height: 23px !important;
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-3w2yfm-ValueContainer {
    padding: 1px 5px !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: Poppins,Roboto,Helvetica,Arial,sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  .w-33 {
    width: 100%;
  }
}

.track_i::-webkit-scrollbar-track {
  background: #f7dffb;
}

.thumb_i::-webkit-scrollbar-thumb {
  background: #9A21AD;
}

.track_i:hover::-webkit-scrollbar-track {
  background: #f7dffb;
}

.thumb_i:hover::-webkit-scrollbar-thumb {
  background: #9A21AD;
}
/* 
.input-field {
  @apply border border-transparent focus:bg-white w-1/2 rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500;
}

.select-field {
  @apply bg-white border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 focus:border-teal-500 focus:outline-none;
} */

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  header {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 5px;
    /* height: 50px; */
    margin: 0px 0px 55px 0px;
  }

  footer {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 5px;
    margin: 50px 0px 0px 0px;
    /* height: 50px; */
  }

  /* .pagebreak {
    page-break-before: always;
  } */
  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 100px;
  }
}

/* @page {
  size: 297mm 210mm;
} */

@media all {
  .pagebreak {
    /* display: none; */
    page-break-before: auto;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.height {
  height: 100%;
  padding: 70px 0px 0px 0px;
}

.nav_height {
  height: 70px;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  /* height: 9%; */
}

.unCheqColor {
  accent-color: #e74359;
}

/* //    "priviusColor":"priviusColor" */

.priviusColor {
  background-color: #e74359;

}

.transparentWhiteBg {
  background: rgb(255 255 255 / 80%);
}


.thin::-webkit-scrollbar-thumb {
  width: 3px;
  /* Adjust the width of the scrollbar thumb */
}

.thin::-webkit-scrollbar-track {
  width: 3px;
  /* Adjust the width of the scrollbar track */
}

/* Green */
.txt_color_g {
  color: #3A7555;
}

.tHeadColor_g {
  background-color: #3A7555;
  color: white;
}

.cheqColor_G {
  accent-color: #15803d;
}

.hoverBg-g:hover {
  background-color: #dcfce7;

}

.hoverBg-t:hover {
  background-color: #d5ecec;
}

.hoverBg-i:hover {
  background-color: #f1dcf4;
}

.hoverBg-m:hover {
  background-color: #ebd6d7;
}

.hoverBg-b:hover {
  background-color: #c4d7e9;
}

.hoverBg-c:hover {
  background-color: #c2c4c7;
}

.hoverBg-lg:hover {
  background-color: #dcfce7;
}

.track_g::-webkit-scrollbar-track {
  background: #dcfce7;
}

.thumb_g::-webkit-scrollbar-thumb {
  background: #dcfce7;
}

.track_g:hover::-webkit-scrollbar-track {
  background: #dcfce7;
}

.thumb_g:hover::-webkit-scrollbar-thumb {
  background: #3A7555;
}

.tableBg_g {
  background-color: #EBF8F1;
}

.bG_Color_g {
  border: 1px solid green;
}

.panelBg {
  background-color: #f5fff9;
}

/* Light Green */
.txt_color_lg {
  color: #27ae61;
}

.tHeadColor_lg {
  background-color: #27ae61;
  color: white;
}

.cheqColor_lg {
  accent-color: #097a54;
}

.scrollable-element:hover {
  scrollbar-color: #3A7555 #dcfce7;
}

.track_g:hover::-webkit-scrollbar-track {
  background: #dcfce7;
}

.thumb_g:hover::-webkit-scrollbar-thumb {
  background: #3A7555;
}

.tableBg_g {
  background-color: #EBF8F1;
}

.bG_Color_ {
  border: 1px solid green;
}

/* teal=======*/
.txt_color_t {
  color: #016367;
}

.tHeadColor_t {
  background-color: #008f95;
  color: white;
}

.cheqColor_T {
  accent-color: #01949A;
}

.track_t::-webkit-scrollbar-track {
  background: #c1e0e1;
}

.thumb_t::-webkit-scrollbar-thumb {
  background: #016367;
}

.track_t:hover::-webkit-scrollbar-track {
  background: #c1e0e1;
}

.thumb_t:hover::-webkit-scrollbar-thumb {
  background: #016367;
}

.scrollable-element-t:hover {
  scrollbar-color: #016367 #c1e0e1;
}

.tableBg_t {
  background-color: #eaf5f5;
}

.bG_Color_t {
  border: 1px solid teal;
}

/* blue */
.txt_color_b {
  color: #3e88ca;
}

.tHeadColor_b {
  background-color: #3E88CA;
  color: white;
}

.cheqColor_b {
  accent-color: #3E88CA;
}

.track_b::-webkit-scrollbar-track {
  background: #C4D7E9;
}

.thumb_b::-webkit-scrollbar-thumb {
  background: #215380;
}

.track_b:hover::-webkit-scrollbar-track {
  background: #C4D7E9;
}

.thumb_b:hover::-webkit-scrollbar-thumb {
  background: #215380;
}

.scrollable-element-b:hover {
  scrollbar-color: #215380 #C4D7E9;
}

.tableBg_b {
  background-color: #e0eaf4;
}

.bG_Color_b {
  border: 1px solid #3e88ca;
}

/* maroon */
.txt_color_m {
  color: #842528;
}

.tHeadColor_m {
  background-color: #A44548;
  color: white;
}

.cheqColor_M {
  accent-color: #A44548;
}

.track_m::-webkit-scrollbar-track {
  background: #ddc1c1;
}

.thumb_m::-webkit-scrollbar-thumb {
  background: #842528;
}

.track_m:hover::-webkit-scrollbar-track {
  background: #ddc1c1;
}

.thumb_m:hover::-webkit-scrollbar-thumb {
  background: #842528;
}

.scrollable-element-m:hover {
  scrollbar-color: #842528 #ddc1c1;
}

.tableBg_m {
  background-color: #f7eeee;
}

.bG_Color_m {
  border: 1px solid maroon;
}

/* Indigo */
.txt_color_i {
  color: #9A21AD;
}

.tHeadColor_i {
  background-color: #BD60CC;
  color: white;
}

.cheqColor_I {
  accent-color: #BD60CC;
}

.track_i::-webkit-scrollbar-track {
  background: #f7dffb;
}

.thumb_i::-webkit-scrollbar-thumb {
  background: #9A21AD;
}

.track_i:hover::-webkit-scrollbar-track {
  background: #f7dffb;
}

.thumb_i:hover::-webkit-scrollbar-thumb {
  background: #9A21AD;
}

.scrollable-element-i:hover {
  scrollbar-color: #9A21AD #f7dffb;
}

.tableBg_i {
  background-color: #fdebff;
}

.bG_Color_i {
  border: 1px solid #9A21AD;
}

/* Charcole */
.txt_color_c {
  color: #313131;
}

.tHeadColor_c {
  background-color: #44474E;
  color: white;
}

.tHeadColor_c {
  background-color: #44474E;
  color: white;
}

.track_c::-webkit-scrollbar-track {
  background: #BBC3CA;
}

.thumb_c::-webkit-scrollbar-thumb {
  background: #40464D;
}

.track_c:hover::-webkit-scrollbar-track {
  background: #BBC3CA;
}

.thumb_c:hover::-webkit-scrollbar-thumb {
  background: #40464D;
}

.scrollable-element-c:hover {
  scrollbar-color: #40464D #BBC3CA;
}

.tableBg_c {
  background-color: #f1f1f1;
}

.bG_Color_c {
  border: 1px solid #44474E;
}










.tableHead {
  background-color: #3E88CA;
}

/* .border-table {
  border: 1px solid #3A7555;
  border: 1px solid #3E88CA;
  border-radius: 5px;
} */


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.pageInner {
  margin: 20px;
}

.logoCFC {
  border-radius: 10px;
  margin-right: 10px;
}





@media (min-width: 600px) {

  .css-10ltm04-MuiToolbar-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* padding-right: 24px; */
  }
}





.w-20 {
  /* width: 5.3rem; */
  /* padding: 0px 0px 0px 14px; */
}

.w-50 {
  width: 50%;
}

.dateNav {
  margin-right: 12px !important;
  /* margin-top: -7px !important; */
  font-weight: bold;
}

.dateNav2 {
  margin-right: 9px !important;
}

.logoDateText {
  font-size: 10px;
}

.logoTimeMain {
  display: flex;
  align-items: baseline;
  float: right;
  justify-content: center;
}

.logoTimeText {
  font-size: 17px;
}

.d-none {
  display: none;
}

.displayFlex {
  display: flex;
  /* justify-content: end; */
  align-items: center;
  width: 14%;
}

.leftRightMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.padding10 {
  padding: 10px
}

.btnLeftSpacingExpanded {
  left: 152px;
}

.btnLeftSpacingExpandedWithSearch {
  left: 230px;
}

.pageBackground {
  background-color: #DBE3DF;
}

tr.cancelled {
  color: #888;
  background-color: #eee;
}

.cic_version,
.cic_tp_version,
.cic_bf_version {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  color: #eee;
  background-color: #555;
  border-radius: 3px;
  padding: 1px 3px 2px 3px;
  font-size: 11px;
  line-height: 85%;
  font-weight: bold;
}

.cycleType_ {
  background-color: #fff;
}

.cycleType_IVF {
  background-color: #60d190;
}

.cycleType_IVF.magnetSelected {
  background-color: #a6e5c1;
}

.cycleType_IVF.magnetAlert {
  background-color: #fb4401;
}

.cycleType_IVF.donor {
  background-color: #f4588a;
}

.cycleType_IVF.donor.magnetSelected {
  background-color: #f788ac;
}

.cycleType_IVF.donor.magnetAlert {
  background-color: #f84c35;
}

.cycleType_FET {
  background-color: #005FB5;
}

.cycleType_FET.magnetSelected {
  background-color: #2e7cc2;
}

.cycleType_FET.magnetAlert {
  background-color: #b93201;
}

.cycleType_FOT {
  background-color: #005FB5;
}

.cycleType_FOT.magnetSelected {
  background-color: #2e7cc2;
}

.cycleType_FOT.magnetAlert {
  background-color: #b93201;
}

.cycleType_FOC {
  background-color: #00c0e2;
}

.cycleType_FOC.magnetSelected {
  background-color: #42cfe8;
}

.cycleType_FOC.magnetAlert {
  background-color: #fb4401;
}

.cycleType_IUI {
  background-color: #DDDDDD;
}

.cycleType_IUI.magnetSelected {
  background-color: #ddf1f1;
}

.cycleType_IUI.magnetAlert {
  background-color: #ff8a5f;
}

.cycleType_OI {
  background-color: #DDDDDD;
}

.cycleType_OI.magnetSelected {
  background-color: #ddf1f1;
}

.cycleType_OI.magnetAlert {
  background-color: #ff8a5f;
}

.cycleType_DI {
  background-color: #DDDDDD;
}

.cycleType_DI.magnetSelected {
  background-color: #ddf1f1;
}

.cycleType_DI.magnetAlert {
  background-color: #ff8a5f;
}

td.cycleType_DI,
.cycleType_DI {
  /* background-image: url(/workdesk/images/skin/di_dot_20.png); */
  background-position: bottom right;
  background-repeat: no-repeat;
}

.btnLeftSpacingUnExpanded {
  left: 3px;
}

.unMergedSideBarDiv {
  width: 180px;
}

@media only screen and (max-width:768px) {
  .unMergedSideBarDiv {
    position: absolute;
    z-index: 7;
    height: 100%;
  }

  .unMergedSideBarDivWithSearch {
    /* width: 100%!important; */
    position: absolute;
    z-index: 7;
    height: 100%;
  }

  .btnLeftSpacingExpandedWithSearch {
    left: 268px;
  }

  .sidbarabsolute {
    height: 100%;
    position: absolute;
  }

  .btnLeftSpacingExpanded {
    left: 168px;
  }

  .displayFlex {
    display: none;
  }

  .w-CoupleDetailsNav {
    display: none;
  }
}

.unMergedSideBarDivWithSearch {
  width: 280px;
}


.wrapper {
  padding: 20px;
  background: #eaeaea;
  max-width: 400px;
  margin: 50px auto;
}


.textOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}

.textOverflowNav {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 230px;
}

.logoImg {
  border-radius: 10px;
  width: 15px;
  height: 15px;
}

.btn-edit {
  border: 1px solid #afbcbc;
  border-radius: 5px;
  background: #4863dddf;
}

tr.cancelled {
  color: #888;
  background-color: #eee;
}


.item-active-text {
  color: #fff;
}

.w-CoupleDetailsNav {
  width: 69%;
  /* width: 464px; */
}

.borderColorRed {
  border-color: #A91111;
}

.bg-green {
  background-color: #BDD7B0D9;
  /* background-color: #E4E4E4; */
}

.art-nav-border {
  border-right: 0.2px solid #3a755531;
}

/* .wdith-10 {
  width: 9%;
} */

.textOverflowNavART {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .ArtNavHover :hover,
.activeART {
  background-color: #3A7555;
  color: #fff;
} */



.magnetSideButton {
  z-index: 60;
  position: absolute;
  width: 37px;
  height: 37px;
  right: 8px;
  bottom: 10px;
  border-radius: 50%;
  background: #b5b5b5a1;
  background: #b5b5b5a1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;
}

.magnetSideButton:hover {
  background: #d8d2d28f;
}



/* .bg-light-green {
  background-color: #BBDAAF;
  background-color: #BBC3CA;
} */

/* .hover\:bg-green-700:hover {
  background-color: #3A7555;
} */

/* .h-88 {
  height: 88vh;
} */

.minWidthDay {
  min-width: 30px;
}

select option[value="0"] {
  color: rgb(141, 134, 134);
  /* Your style for the option with value="0" */
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {

  margin: 5px !important;
  line-height: 0.9rem !important;

}
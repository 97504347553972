.wb_note {
    border-radius: 9px;

    margin-bottom: 3px;
    background-color: #FFFFEE;
    cursor: default;
}

.wb_note:hover {
    border-radius: 9px;

    margin-bottom: 3px;
    background-color: #FFFFCC;
}

.wmnSticky {
    background-color: #ECE479 !important;
}

.wmnColletion {
    background-color: #cae8c2 !important;
}

.wmnAlert {
    background-color: #fbaebb !important;
}



.flashRow {
    animation-name: msgflash;
    -webkit-animation-name: msgflash;
    animation-iteration-count: 3;
    -webkit-animation-iteration-count: 3;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
    animation-duration: 0.35s;
    -webkit-animation-duration: 0.35s;
}

@keyframes msgflash {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bakImg {
    /* background-image: url(../../Assets/images/3.jpg); */
    width: 100%;

}

.icon_32_frozen {
    background-position: -180px -180px;
    background-image: url('/src/Assets/images/_sprite_ic32_all.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 36px;
    height: 36px;
}

.icon_32_frozen_sperm {
    background-position: -216px -180px;
    background-image: url('/src/Assets/images/_sprite_ic32_all.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 36px;
    height: 36px;
}

.icon_32_frozen_oocyte {
    background-image: url('/src/Assets/images/_sprite_ic32_all.png');
    background-repeat: no-repeat;
    display: inline-block;
    background-position: -252px -180px;
    width: 36px;
    height: 36px;
}

.icon_32_frozen_embryo {
    background-image: url('/src/Assets/images/_sprite_ic32_all.png');
    background-repeat: no-repeat;
    display: inline-block;
    background-position: -288px -180px;
    width: 36px;
    height: 36px;
}

.icon_32_egg {
    background-image: url('/src/Assets/images/_sprite_ic32_all.png');
    background-repeat: no-repeat;
    display: inline-block;
    background-position: -324px -180px;
    width: 36px;
    height: 36px;
}
.with-33{
    width: 33%;
}
@keyframes buzz {
    0% {
      transform: translateX(-2px);
    }
    50% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }
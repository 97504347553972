.loader-css{
    position: absolute;
    background: #000000c7;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    justify-content: center;
    align-items: center;
    display: flex
}